import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AddButton = _resolveComponent("AddButton")!

  return (_openBlock(), _createBlock(_component_AddButton, {
    loading: _ctx.disabled,
    class: "mb-2",
    onClick: _ctx.onButtonClicked
  }, {
    default: _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.element.label), 1)
    ]),
    _: 1
  }, 8, ["loading", "onClick"]))
}