import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, Transition as _Transition } from "vue"

const _hoisted_1 = {
  ref: "intersectionTarget",
  class: "relative min-h-[426px] w-full flex items-center justify-center border border-gray-200 rounded-md overflow-hidden"
}
const _hoisted_2 = { class: "absolute z-10 top-0 left-0 py-4 px-6" }
const _hoisted_3 = {
  key: 0,
  class: "absolute inset-0 bg-white flex items-center justify-center"
}
const _hoisted_4 = {
  key: 1,
  class: "w-full m-3"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Spinner = _resolveComponent("Spinner")!
  const _component_GenericChart = _resolveComponent("GenericChart")!
  const _component_EmptyChartState = _resolveComponent("EmptyChartState")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.row.section_name), 1),
    _createVNode(_Transition, {
      name: "fade-fast",
      mode: "out-in"
    }, {
      default: _withCtx(() => [
        (!_ctx.hasIntersected || _ctx.isLoading)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _createVNode(_component_Spinner, { class: "h-12 w-12 mx-auto" })
            ]))
          : (_openBlock(), _createElementBlock("div", _hoisted_4, [
              _createVNode(_component_EmptyChartState, { options: _ctx.chartOptions }, {
                default: _withCtx(() => [
                  _createVNode(_component_GenericChart, {
                    options: (_ctx.chartOptions as any),
                    onReady: _ctx.setChartInstance,
                    onUpdate: _ctx.setChartInstance
                  }, null, 8, ["options", "onReady", "onUpdate"])
                ]),
                _: 1
              }, 8, ["options"])
            ]))
      ]),
      _: 1
    })
  ], 512))
}