import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "divide-y divide-gray-200 space-y-4 px-4 py-4" }
const _hoisted_2 = { class: "flex justify-between items-center space-x-3" }
const _hoisted_3 = { class: "flex items-center space-x-3" }
const _hoisted_4 = { class: "font-bold text-lg" }
const _hoisted_5 = { class: "text-sm text-gray-500" }
const _hoisted_6 = {
  key: 0,
  class: "pt-6 space-y-6"
}
const _hoisted_7 = { class: "flex justify-between items-center space-x-3" }
const _hoisted_8 = { class: "text-lg font-medium" }
const _hoisted_9 = {
  key: 1,
  class: "text-center font-medium pt-6 pb-2"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!
  const _component_SwitchButton = _resolveComponent("SwitchButton")!
  const _component_FoodChart = _resolveComponent("FoodChart")!
  const _component_MainViewCard = _resolveComponent("MainViewCard")!

  return (_openBlock(), _createBlock(_component_MainViewCard, null, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", null, [
              _createVNode(_component_Icon, {
                class: "h-6",
                src: _ctx.IconSource.Location
              }, null, 8, ["src"])
            ]),
            _createElementVNode("div", null, [
              _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.barn.headers.barn_name), 1),
              _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.barn.rows?.length || 0) + " " + _toDisplayString(_ctx.barn.rows?.length === 1 ? _ctx.$t('views.monitoring.main.headings.section') : _ctx.$t('views.monitoring.main.headings.sectionPlural')), 1)
            ])
          ])
        ]),
        (_ctx.barn.rows && _ctx.barn.rows.length)
          ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
              _createElementVNode("div", _hoisted_7, [
                _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.isHourly ? _ctx.$t('views.monitoring.charts.titles.feedingSystemRuntimePerHour') : _ctx.$t('views.monitoring.charts.titles.feedingSystemRuntimePerDay')), 1),
                _createVNode(_component_SwitchButton, {
                  modelValue: _ctx.isHourly,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.isHourly) = $event)),
                  disabled: _ctx.isLoading,
                  "true-text": _ctx.$t('views.monitoring.main.charts.hourlyLabel'),
                  "false-text": _ctx.$t('views.monitoring.main.charts.dailyLabel')
                }, null, 8, ["modelValue", "disabled", "true-text", "false-text"])
              ]),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.barn.rows, (row) => {
                return (_openBlock(), _createBlock(_component_FoodChart, {
                  key: row.section_id,
                  row: row
                }, null, 8, ["row"]))
              }), 128))
            ]))
          : (_openBlock(), _createElementBlock("div", _hoisted_9, _toDisplayString(_ctx.$t('views.monitoring.main.tables.noSectionsText')), 1))
      ])
    ]),
    _: 1
  }))
}