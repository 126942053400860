<template>
    <MainViewCard>
        <div class="divide-y divide-gray-200 space-y-4 px-4 py-4">
            <div class="flex justify-between items-center space-x-3">
                <div class="flex items-center space-x-3">
                    <div>
                        <Icon class="h-6" :src="IconSource.Location"></Icon>
                    </div>
                    <div>
                        <div class="font-bold text-lg">{{ barn.headers.barn_name }}</div>
                        <div class="text-sm text-gray-500">
                            {{ barn.rows?.length || 0 }} {{ barn.rows?.length === 1 ? $t('views.monitoring.main.headings.section') : $t('views.monitoring.main.headings.sectionPlural') }}
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="barn.rows && barn.rows.length" class="pt-6 space-y-6">
                <div class="flex justify-between items-center space-x-3">
                    <div class="text-lg font-medium">
                        {{ isHourly ? $t('views.monitoring.charts.titles.waterConsumptionLiterPerHour') : $t('views.monitoring.charts.titles.waterConsumptionLiterPerDay') }}
                    </div>
                    <SwitchButton v-model="isHourly" :disabled="isLoading" :true-text="$t('views.monitoring.main.charts.hourlyLabel')" :false-text="$t('views.monitoring.main.charts.dailyLabel')" />
                </div>
                <WaterChart v-for="row in barn.rows" :key="row.section_id" :row="row" />
            </div>
            <div v-else class="text-center font-medium pt-6 pb-2">
                {{ $t('views.monitoring.main.tables.noSectionsText') }}
            </div>
        </div>
    </MainViewCard>
</template>

<script lang="ts">
import { defineComponent, ref, provide, computed } from 'vue';
import { useIsFetching } from '@tanstack/vue-query';
import MainViewCard from '@/components/common/MainViewCard.vue';
import Icon from '@/components/icons/Icon.vue';
import { IconSource, MonitoringDataBarn } from '@/types';
import WaterChart from './WaterChart.vue';
import { WATER_CHARTS_INJECTION_KEY } from './injectionKeys';
import SwitchButton from '@/components/ui/SwitchButton.vue';
import { queryKeys } from '@/plugins/store/actions/queries/monitoring/charts';

export default defineComponent({
    components: { MainViewCard, Icon, WaterChart, SwitchButton },
    props: {
        barn: {
            type: Object as () => MonitoringDataBarn,
            required: true,
        },
    },
    setup() {
        const chartInstances = ref<Record<number, any>>({});
        const isChartsUpdating = ref(false);
        const isHourly = ref(true);
        const isFetching = useIsFetching({ queryKey: queryKeys.monitorWaterConsumptionCharts() });
        const isLoading = computed(() => isFetching.value > 0);

        provide(WATER_CHARTS_INJECTION_KEY, {
            chartInstances,
            isChartsUpdating,
            isHourly,
        });

        return {
            IconSource,
            isHourly,
            isLoading,
        };
    },
});
</script>
