import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, withCtx as _withCtx, Transition as _Transition } from "vue"

const _hoisted_1 = { class: "space-y-4" }
const _hoisted_2 = { class: "flex justify-end" }
const _hoisted_3 = {
  key: 0,
  class: "flex justify-center p-6 items-center space-x-2"
}
const _hoisted_4 = {
  key: 0,
  class: "space-y-4"
}
const _hoisted_5 = {
  key: 0,
  class: "absolute inset-0 bg-white flex items-center justify-center"
}
const _hoisted_6 = {
  key: 1,
  class: "w-full m-3"
}
const _hoisted_7 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SwitchButton = _resolveComponent("SwitchButton")!
  const _component_Spinner = _resolveComponent("Spinner")!
  const _component_GenericChart = _resolveComponent("GenericChart")!
  const _component_EmptyChartState = _resolveComponent("EmptyChartState")!
  const _component_ExpandCollapseTransition = _resolveComponent("ExpandCollapseTransition")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_SwitchButton, {
        modelValue: _ctx.isHourly,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.isHourly) = $event)),
        "true-text": _ctx.$t('views.monitoring.main.charts.hourlyLabel'),
        "false-text": _ctx.$t('views.monitoring.main.charts.dailyLabel'),
        disabled: _ctx.isFetching
      }, null, 8, ["modelValue", "true-text", "false-text", "disabled"])
    ]),
    (_ctx.isLoading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createElementVNode("span", null, _toDisplayString(_ctx.$t('views.monitoring.main.charts.loadingText')), 1),
          _createVNode(_component_Spinner, { class: "h-6 w-6" })
        ]))
      : _createCommentVNode("", true),
    _createVNode(_component_ExpandCollapseTransition, null, {
      default: _withCtx(() => [
        (_ctx.chartsData)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.chartsData, (chart, chartIndex) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: chart.id,
                  class: "relative min-h-[426px] w-full flex items-center justify-center border border-gray-200 rounded-md overflow-hidden"
                }, [
                  _createVNode(_Transition, {
                    name: "fade-fast",
                    mode: "out-in"
                  }, {
                    default: _withCtx(() => [
                      (_ctx.isFetching)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                            _createVNode(_component_Spinner, { class: "h-12 w-12 mx-auto" })
                          ]))
                        : (_openBlock(), _createElementBlock("div", _hoisted_6, [
                            _createVNode(_component_EmptyChartState, {
                              options: chart.options
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_GenericChart, {
                                  options: (chart.options as any),
                                  onReady: ($event: any) => (_ctx.chartInstances[chartIndex] = $event),
                                  onUpdate: ($event: any) => (_ctx.chartInstances[chartIndex] = $event)
                                }, null, 8, ["options", "onReady", "onUpdate"])
                              ]),
                              _: 2
                            }, 1032, ["options"])
                          ]))
                    ]),
                    _: 2
                  }, 1024)
                ]))
              }), 128))
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    (!_ctx.isLoading && !_ctx.chartsData)
      ? (_openBlock(), _createElementBlock("div", _hoisted_7, _toDisplayString(_ctx.$t('views.monitoring.main.charts.couldNotLoadChartsText')), 1))
      : _createCommentVNode("", true)
  ]))
}