<template>
    <AddButton :loading="disabled" class="mb-2" @click="onButtonClicked">
        {{ element.label }}
    </AddButton>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import AddButton from '@/components/ui/AddButton.vue';
import Spinner from '@/components/ui/Spinner.vue';
import { CloneFormSectionOptions, FormElement } from '@/types';

export default defineComponent({
    components: { AddButton, Spinner },
    emits: ['clone-section', 'backend-retrieval'],
    props: {
        element: {
            type: Object as () => FormElement,
            required: true,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        onButtonClicked() {
            switch (this.element.type_settings.action) {
                case 'clone-section': {
                    this.$emit('clone-section', {
                        sectionReference: this.element.type_settings.reference,
                        newSectionName: this.element.type_settings.values.find((currentValue: { id: string; value: null | string }) => currentValue.id === 'cloned-section-name')?.value,
                    } as CloneFormSectionOptions);
                    break;
                }
                case 'backend-retrieval': {
                    this.$emit('backend-retrieval', this.$props.element);
                    break;
                }
                default: {
                }
            }
        },
    },
});
</script>
