<template>
    <Modal close-esc-event="keydown" :close-button="false" :modal-class="`m-auto border-0 max-w-6xl transition-width duration-500`" mask-class="grid">
        <template #toggle="scope">
            <slot v-bind="scope" />
        </template>

        <template #header="scope">
            <CloseModalButton @close="scope.close"></CloseModalButton>
        </template>

        <template #body="scope">
            <div class="bg-white w-full relative rounded-lg grid py-6 px-10 m-auto space-y-4 max-w-128">
                <MultiStepFormHeaderIcon v-if="header" :icon-source="header.icon_url" :is-final-step="false"></MultiStepFormHeaderIcon>
                <h2 class="text-xl font-semibold text-center">{{ texts.header }}</h2>
                <p class="text-center">
                    <span>{{ texts.message }}</span>
                </p>
                <div class="flex space-x-4">
                    <CustomButton :disabled="disableButtons" color-preset="secondary" @click="scope.close">
                        {{ texts.cancelButtonLabel }}
                    </CustomButton>
                    <CustomButton :disabled="disableButtons" color-preset="warning" @click="onDeleteRecordClicked(scope.close)">
                        {{ texts.confirmButtonLabel }}
                    </CustomButton>
                </div>
            </div>
        </template>
    </Modal>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { Modal } from '@makeabledk/vue-ui/components/modal';
import { Form } from '@makeabledk/vue-ui/support/http';
import CustomButton from '@/components/ui/CustomButton.vue';
import { MultiStepFormState } from '@/types';
import MultiStepFormHeaderIcon from '@/components/common/MultiStepFormHeaderIcon.vue';
import { ActionType } from '@/plugins/store/actions';
import { store } from '@/plugins/store';
import CloseModalButton from '@/components/common/CloseModalButton.vue';
import { MutationType } from '@/plugins/store/mutations';

export default defineComponent({
    components: { Modal, MultiStepFormHeaderIcon, CustomButton, CloseModalButton },
    emits: ['delete', 'update:state'],
    props: {
        state: {
            type: Object as () => MultiStepFormState & Form<MultiStepFormState>,
            required: true,
        },
        header: {
            type: Object as () => null | { icon_url: string; text: string },
            default: null,
        },
        type: {
            type: String as () => 'RECORD' | 'ASSIGNMENT' | 'ASSIGNMENT_TEMPLATE' | 'NOTE',
            default: 'RECORD',
        },
    },
    data() {
        return { disableButtons: false };
    },
    computed: {
        computedState: {
            get(): MultiStepFormState & Form<MultiStepFormState> {
                return this.$props.state;
            },
            set(newValue: MultiStepFormState) {
                this.$emit('update:state', newValue);
            },
        },
        texts() {
            switch (this.type) {
                case 'ASSIGNMENT':
                    return {
                        header: this.$t('global.forms.steps.deleteAssignment.header'),
                        message: this.$t('global.forms.steps.deleteAssignment.message'),
                        cancelButtonLabel: this.$t('global.forms.steps.deleteAssignment.cancelButtonLabel'),
                        confirmButtonLabel: this.$t('global.forms.steps.deleteAssignment.confirmButtonLabel'),
                        deleteSuccess: this.$t('global.forms.steps.deleteAssignment.deleteSuccess'),
                    };
                case 'ASSIGNMENT_TEMPLATE':
                    return {
                        header: this.$t('global.forms.steps.deleteAssignmentTemplate.header'),
                        message: this.$t('global.forms.steps.deleteAssignmentTemplate.message'),
                        cancelButtonLabel: this.$t('global.forms.steps.deleteAssignmentTemplate.cancelButtonLabel'),
                        confirmButtonLabel: this.$t('global.forms.steps.deleteAssignmentTemplate.confirmButtonLabel'),
                        deleteSuccess: this.$t('global.forms.steps.deleteAssignmentTemplate.deleteSuccess'),
                    };
                default:
                    return {
                        header: this.$t('global.forms.steps.deleteRecord.header'),
                        message: this.$t('global.forms.steps.deleteRecord.message'),
                        cancelButtonLabel: this.$t('global.forms.steps.deleteRecord.cancelButtonLabel'),
                        confirmButtonLabel: this.$t('global.forms.steps.deleteRecord.confirmButtonLabel'),
                        deleteSuccess: this.$t('global.forms.steps.submission.deleteSuccess'),
                    };
            }
        },
    },
    methods: {
        getActionType() {
            switch (this.type) {
                case 'ASSIGNMENT':
                    return ActionType.DeleteAssignment;
                case 'ASSIGNMENT_TEMPLATE':
                    return ActionType.DeleteAssignmentTemplate;
                case 'NOTE':
                    return ActionType.DeleteNote;
                default:
                    return ActionType.DeleteRecord;
            }
        },
        getFormPayload() {
            switch (this.type) {
                case 'ASSIGNMENT':
                    return { assignment_id: this.computedState.assignment_id };
                case 'ASSIGNMENT_TEMPLATE':
                    return { assignment_template_id: this.computedState.assignment_template_id };
                case 'NOTE':
                    return { note_id: this.computedState.note_id };
                default:
                    return { record_id: this.computedState.record_id };
            }
        },
        async onDeleteRecordClicked(close: CallableFunction) {
            if (this.disableButtons) {
                return;
            }
            this.disableButtons = true;

            const actionType = this.getActionType();
            const formPayload = this.getFormPayload();

            await store.dispatch(actionType, { form: formPayload as any });
            // TODO: Disable this re-fetches in some cases
            store.dispatch(ActionType.GetFarms, { options: { ignoreCache: true } });
            store.dispatch(ActionType.GetBarns, { options: { ignoreCache: true } });
            store.dispatch(ActionType.GetSections, { options: { ignoreCache: true } });
            store.commit(MutationType.SetFormRecentlySubmitted, true);
            this.$nextTick(() => {
                store.commit(MutationType.SetFormRecentlySubmitted, false);
            });
            this.computedState.fill({ submissionMessage: this.texts.deleteSuccess });
            close();
            this.$emit('delete');
        },
    },
});
</script>
